import { createRouter, createWebHashHistory } from 'vue-router'
import { routes } from './routes.js'
import { checkSession } from '@/services/posUserSessionService.js'

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async to => {
  const { meta: { requiresSession } } = to

  if (!requiresSession) {
    return
  }

  const auth = await checkSession()
  if (!auth) {
    return { name: 'login' }
  }
})

export default router
