<script setup>
  import { onBeforeMount } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { PactoToast } from '@getpacto/pacto-ecom-ui'
  import { usePactoStore } from '@/stores/pacto'
  import { setI18nLanguage, loadLocaleMessages } from './i18n.js'

  const i18n = useI18n()
  const pactoStore = usePactoStore()

  onBeforeMount(async () => {
    await loadLocaleMessages(i18n, 'es')
    setI18nLanguage(i18n, 'es')
  })
</script>

<template>
  <div class="toasts-wrapper">
    <TransitionGroup name="list">
      <PactoToast
        v-for="({ title, description, id, mode }) in pactoStore.toasts"
        :key="id"
        :title="title"
        :description="description"
        :mode="mode"
        @remove-toast="pactoStore.removeToast(id)"
      />
    </TransitionGroup>
  </div>
  <section class="light-theme main">
    <router-view name="header" />
    <router-view name="subheader" />
    <router-view />
  </section>
</template>

<style lang="scss" scoped>
.toasts-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 11;
}

.toast {
  margin: 8px;
  width: calc(100% - 16px);
}

@media (min-width: $breakpoint-small) {
  .toast {
    margin: 16px 32px;
    width: calc(100% - 64px);
  }
}

.main {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  opacity: 1;
  overflow: hidden;
  position: relative;
  z-index: 5;
  flex-grow: 1;
}
</style>
