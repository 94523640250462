import { checkSession } from '@/services/posUserSessionService'

const basicMetaProps = {
  requiresSession: true,
  allowLongPoll: true
}
const pactoHeaderProfile = {
  showSettings: true
}

export const routes = [{
  path: '/',
  name: 'login',
  component: () => import('@/views/LoginView.vue'),
  beforeEnter: async () => {
    try {
      const isAuth = await checkSession()
      if (isAuth) {
        return { name: 'profile' }
      }
    } catch (err) {}
  }
}, {
  path: '/profile',
  name: 'profile',
  components: {
    header: () => import('@/components/structure/PactoHeader.vue'),
    default: () => import('@/views/ProfileView.vue')
  },
  props: {
    header: pactoHeaderProfile
  },
  meta: basicMetaProps
}, {
  path: '/:pathMatch(.*)',
  name: 'not-found',
  component: () => import('@/views/NotFoundView.vue')
}]
