import { generateClient } from 'aws-amplify/api'

const client = generateClient()

export const customUpdatePinMutation = async function (params, variablesInput) {
  const customMutation = `
    mutation UpdatePin($input: UpdatePinInput) {
      updatePin(input: $input) {
          ${params}
        }
      }
    `

  const { data } = await client.graphql({
    query: customMutation,
    variables: {
      input: {
        ...variablesInput
      }
    }
  })

  return data
}
