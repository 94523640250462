import { fetchAuthSession } from 'aws-amplify/auth'
import { createApp, markRaw } from 'vue'
import { setupI18n } from './i18n.js'
import { createPinia } from 'pinia'
import { Amplify } from 'aws-amplify'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/browser'
import router from './router'
import App from './App.vue'
import awsExports from './aws-exports'

import '../node_modules/@getpacto/pacto-ecom-ui/dist/styles/loaderSpinner.scss'
import '../node_modules/@getpacto/pacto-ecom-ui/dist/styles/button.scss'
import '../node_modules/@getpacto/pacto-ecom-ui/dist/styles/pactoToast.scss'
import '../node_modules/@getpacto/pacto-ecom-ui/dist/styles/dragScreen.scss'
import '../node_modules/@getpacto/pacto-ecom-ui/dist/styles/formField.scss'

Amplify.configure(awsExports, {
  API: {
    GraphQL: {
      headers: async () => ({
        Authorization: (await fetchAuthSession()).tokens?.idToken?.toString()
      })
    }
  }
})

const i18n = setupI18n({
  legacy: false,
  globalInjection: true
})
const pinia = createPinia()
const app = createApp(App)

pinia.use(({ store }) => {
  store.$router = markRaw(router)
})

if (!import.meta.env.DEV) {
  Sentry.init({
    app,
    // eslint-disable-next-line no-undef
    release: `${VITE_APP_NAME}@${VITE_APP_VERSION}`,
    environment: import.meta.env.VITE_SENTRY_ENV,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    ...(import.meta.env.VITE_SENTRY_ENV !== 'prod' && {
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        }),
        new Sentry.Replay()
      ],
      replaysSessionSampleRate: import.meta.env.VITE_SENTRY_SAMPLE_RATE,
      replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_SAMPLE_RATE,
    }),
    tracesSampleRate: import.meta.env.VITE_SENTRY_SAMPLE_RATE,
    attachProps: true,
    attachStacktrace: true
  })
}

app.use(router)
app.use(pinia)
app.use(i18n)
app.mount('#app')
