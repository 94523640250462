import { defineStore } from 'pinia'

export const usePactoStore = defineStore('pacto', {
  state: () => ({
    toasts: []
  }),
  actions: {
    addToast (toast) {
      const id = setTimeout(() => {
        this.removeToast()
      }, '3500')
      toast.id = id
      this.toasts.push(toast)
    },
    removeToast (id) {
      if (!id) {
        this.toasts.shift()
        return
      }

      const index = this.toasts.findIndex(toast => toast.id === id)
      this.toasts.splice(index, 1)
      clearTimeout(id)
    }
  }
})
