import { getCurrentUser, signIn, confirmSignIn, signOut } from 'aws-amplify/auth'
import { customUpdatePinMutation } from '@/graphql/customMutation'
import { getUserQuery } from '@/graphql/customQueries'

const sendUserEmail = async function ({ username }) {
  const successResponse = 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE'
  const { nextStep } = await signIn({
    username,
    options: {
      authFlowType: 'CUSTOM_WITHOUT_SRP'
    }
  })

  const success = nextStep.signInStep === successResponse
  return { success }
}

const confirmUserCode = async function ({ challengeResponse }) {
  const response = await confirmSignIn({ challengeResponse })
  return { success: response.isSignedIn }
}

const checkSession = async function () {
  try {
    const { userId } = await getCurrentUser()
    return userId
  } catch (err) {}
}

const getUserProfileData = async function () {
  const data = `
      pin
      email
      person {
        name
        lastname
      }
      phone
  `

  const { user = {} } = await getUserQuery(data)
  return user
}

const updateUserPin = async function ({ pin, pinConfirm }) {
  const params = `
    id
    pin
  `

  const variablesInput = {
    pin,
    pinConfirm
  }

  const data = await customUpdatePinMutation(params, variablesInput)
  return data
}

const handleSignOut = async function () {
  const response = await signOut()
  return response
}

export {
  checkSession, sendUserEmail,
  confirmUserCode, getUserProfileData,
  updateUserPin, handleSignOut
}
