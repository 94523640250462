import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'

export const availableLocales = ['en', 'es']

export function setupI18n (options = { locale: 'es' }) {
  const i18n = createI18n(options)
  setI18nLanguage(i18n, options.locale)

  return i18n
}

export function setI18nLanguage (i18n, locale) {
  if (i18n.global) {
    i18n.global.locale.value = locale
  } else {
    i18n.locale.value = locale
  }

  document.querySelector('html').setAttribute('lang', locale)
}

export async function loadLocaleMessages (i18n, locale) {
  const messages = await import(`./locales/${locale}.json`)

  i18n.setLocaleMessage(locale, messages.default)

  return nextTick()
}
