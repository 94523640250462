import { generateClient } from 'aws-amplify/api'

const client = generateClient()

export const getUserQuery = async function (params) {
  const customUserQuery = `
    query User {
      user {
        ${params}
      }
    }
  `
  const { data } = await client.graphql({ query: customUserQuery })
  return data
}
